var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.Servicios)?_c('div',[_c('div',{staticClass:"d-none d-md-block"},[_c('div',{staticClass:"contenedor-servicios"},[_c('v-img',{staticClass:"imagen",attrs:{"src":_vm.Servicios.imagen,"max-width":"350px","width":"350px","min-width":"350px"}}),_vm._l((_vm.botones),function(ref,i){
var posicion = ref.posicion;
var icono = ref.icono;
return _c('v-btn',{key:i,staticClass:"boton primario elevation-0",attrs:{"posicion":posicion},on:{"click":function($event){return _vm.cambiarPosicion(posicion)}}},[_c('v-icon',{staticClass:"white--text",attrs:{"x-large":""}},[_vm._v(_vm._s(icono))])],1)}),_vm._l((_vm.arregloServicios),function(ref,i){
var imagen = ref.imagen;
var texto = ref.texto;
return _c('v-card',{key:("c" + i),staticClass:"card elevation-0",attrs:{"posicion":i}},[_c('v-img',{staticClass:"imagen-card",attrs:{"src":imagen,"width":"100%","height":"180px","contain":"","ubicacion":i}}),_c('div',{staticClass:"texto"},[_c('strong',[_vm._v(_vm._s(texto))])])],1)})],2)]),_c('div',{staticClass:"d-md-none"},[_c('v-container',[_c('v-row',{staticClass:"align-items-center justify-center",staticStyle:{"margin-bottom":"100px","margin-top":"3px"}},[_c('v-img',{attrs:{"src":_vm.Servicios.imagen,"max-width":"250px","width":"250px","min-width":"250px"}})],1),_vm._l((_vm.arregloServicios),function(ref,i){
var imagen = ref.imagen;
var texto = ref.texto;
return _c('v-row',{key:("cM" + i),staticClass:"align-items-center justify-center"},[_c('v-card',{staticClass:"card-movil elevation-0",attrs:{"posicion":i}},[_c('v-img',{staticClass:"imagen-card",staticStyle:{"margin-top":"2em"},attrs:{"src":imagen,"width":"170px","height":"140px","contain":""}}),_c('div',{staticClass:"texto"},[_c('h3',[_vm._v(_vm._s(texto))])])],1)],1)}),_c('v-row',{staticClass:"align-items-center justify-center"},_vm._l((_vm.botones),function(ref,i){
var posicion = ref.posicion;
var icono_movil = ref.icono_movil;
return _c('v-btn',{key:i,staticClass:"primario elevation-0 boton-movil",attrs:{"posicion":posicion,"disabled":posicion==="izquierdo" ? _vm.indiceCarrusel ===0 : _vm.indiceCarrusel+3 >= _vm.Servicios.servicios.length},on:{"click":function($event){return _vm.cambiarPosicion(posicion)}}},[(posicion==="izquierdo")?_c('v-icon',{staticClass:"white--text mr-1",attrs:{"x-large":""}},[_vm._v(_vm._s(icono_movil))]):_c('v-icon',{staticClass:"white--text ml-1",attrs:{"x-large":""}},[_vm._v(_vm._s(icono_movil))])],1)}),1)],2)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }