<template lang="pug">
div(v-if='Servicios')
  //-Pc
  .d-none.d-md-block
    .contenedor-servicios
      v-img.imagen(:src='Servicios.imagen' max-width='350px' width='350px' min-width='350px')
      v-btn(v-for='{posicion, icono}, i in botones' :key='i' :posicion='posicion'
        @click='cambiarPosicion(posicion)').boton.primario.elevation-0
        v-icon(x-large).white--text {{icono}}
      v-card(v-for='{imagen, texto}, i in arregloServicios' :key='`c${i}`' :posicion='i').card.elevation-0
        v-img(:src='imagen' width='100%' height='180px' contain :ubicacion='i').imagen-card
        .texto
          strong {{texto}}
  //-Móvil
  .d-md-none
    v-container
      v-row(style='margin-bottom: 100px; margin-top: 3px').align-items-center.justify-center
        v-img(:src='Servicios.imagen' max-width='250px' width='250px' min-width='250px')
      v-row(v-for='{imagen, texto}, i in arregloServicios' :key='`cM${i}`').align-items-center.justify-center
        v-card(:posicion='i').card-movil.elevation-0
          v-img(:src='imagen' width='170px'  
           height='140px' 
          contain style='margin-top:2em').imagen-card
          .texto
            h3 {{texto}}
      v-row.align-items-center.justify-center
        v-btn(v-for='{posicion, icono_movil}, i in botones' :key='i' :posicion='posicion' :disabled='posicion==="izquierdo" ? indiceCarrusel ===0 : indiceCarrusel+3 >= Servicios.servicios.length'
        @click='cambiarPosicion(posicion)').primario.elevation-0.boton-movil
          v-icon(v-if='posicion==="izquierdo"' x-large).white--text.mr-1 {{icono_movil}}
          v-icon(v-else x-large).white--text.ml-1 {{icono_movil}}

</template>
<script>
export default {
  data: ()=>({
    indiceCarrusel: 0,
    botones: [
      {posicion: 'izquierdo', icono: 'fas fa-caret-left', icono_movil: 'fas fa-angle-left'},
      {posicion: 'derecho', icono: 'fas fa-caret-right', icono_movil: 'fas fa-angle-right'},
    ],
  }),
  computed: {
    arregloServicios(){
      return this.Servicios?.servicios.slice(this.indiceCarrusel, this.indiceCarrusel+3)
    },
  },
  methods: {
    cambiarPosicion(posicion){
      if(posicion==='izquierdo') {
        this.indiceCarrusel = this.indiceCarrusel - 3
        if(this.indiceCarrusel < 0) {
          let residuo = this.Servicios.servicios.length%3 
          if(residuo!=0) this.indiceCarrusel = this.Servicios.servicios.length - residuo
          else this.indiceCarrusel = this.Servicios.servicios.length - 3 
        }
      } else {
        if((this.indiceCarrusel+3)>=this.Servicios?.servicios.length) this.indiceCarrusel = 0
        else this.indiceCarrusel = this.indiceCarrusel + 3 
      }
    },
  },
  created(){
    setInterval(() => this.cambiarPosicion("derecho"), 22000)
  },
}
</script>
<style lang="sass" scoped>
.contenedor-servicios
  width: 95%
  margin-top: 10px
  height: 420px
  position: relative
  .imagen
    position: absolute
    top: 10%
    left: 4%
  .boton
    position: absolute
    width: 50px
    height: 50px
    min-width: 50px!important
    border-radius: 50%
    &[posicion='izquierdo']
      top: 30%
      left: 35%
    &[posicion='derecho']
      bottom: 30%
      left: 100%
  .card
    position: absolute
    width: 180px
    height: 190px
    border-radius: 9%
    &[posicion='0']
      top: 10%
      left: 43%
      background-color: #470c74
    &[posicion='1']
      top: 22%
      left: 63%
      background-color: #59dde2
      
    &[posicion='2']
      top: 34%
      left: 83%
      background-color: #e62e4b
    .imagen-card
      position: absolute
      top: -38%
      right: 0.4
      &[ubicacion='2']
        right: 1%
    .texto
      position: absolute
      bottom: 10%
      left: 0
      right: 0
      margin-left: auto
      margin-right: auto
      text-align: center
      width: 110px
      color: white
.card-movil
  width: 320px
  height: 150px
  border-radius: 30px!important
  &[posicion='0']
    background-color: #470c74
    margin-bottom: 79px
  &[posicion='1']
    background-color: #59dde2
    margin-bottom: 79px

  &[posicion='2']
    background-color: #e62e4b
    margin-bottom: 55px

  .imagen-card
    position: absolute
    top: -60%
    left: 0
    right: 0
    margin-left: auto
    margin-right: auto
  .texto
    position: absolute
    bottom: 10%
    left: 0
    right: 0
    margin-left: auto
    margin-right: auto
    text-align: center
    width: 150px
    color: white
.boton-movil
  min-width: 50px!important
  max-width: 50px!important
  width: 50px!important
  min-height: 50px!important
  max-height: 50px!important
  height: 50px!important
  border-radius: 50%
  &[posicion='izquierdo']
    margin-right: 55px

</style>